import styled from 'styled-components';
import {SectionHeading,Commonh6} from '../Common/common.style';
import {device} from '../Common/device';

export const AmenitiesSection = styled.section`
    background:#151515;
    padding:100px 0px 70px;

    @media ${device.laptop} {
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const HeadingSpan = styled.span`
    color:#c5a47e;
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:0px;

    @media ${device.tablet} {
        margin-bottom:20px;
    }
`;
export const AmenitiesLayout = styled.div`
    background:#1c1a18;
    margin-bottom:30px;
    padding:30px;
    text-align:center;
`;

export const AmenitiesImage = styled.img`
    height:50px;
    flex-shrink:0;
    margin-bottom:20px;
`;

export const AmenitiesText = styled(Commonh6)`
    margin-bottom:0px;
    line-height:1;
    text-align:center;

    @media ${device.mobileXL} {
        line-height:1;
    }
`;


